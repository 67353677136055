<template>
    <div>
        <b-alert :show="not_found" variant="danger" fade>
      <span class="text-light-75 font-weight-bold d-block font-size-lg">{{
        $t("COMPONENT.NOT_FOUND")
      }}</span>
        </b-alert>
        <!--begin::Card-->
        <div v-if="!not_found" class="card card-custom">
            <!--begin::Header-->
            <div class="card-header py-3">
                <div class="card-title align-items-start flex-column">
                    <h3 class="card-label font-weight-bolder text-dark">
                        {{
                        edit_mode
                        ? add_new
                        ? $t("COMPONENT.NEW")
                        : $t("COMPONENT.EDIT")
                        : $t("COMPONENT.VIEW")
                        }}
                    </h3>
                    <span class="text-muted font-weight-bold font-size-sm mt-1">{{
            component.name
          }}</span>
                </div>
                <div v-if="edit_mode" class="card-toolbar">
                    <a
                            v-on:click="save()"
                            class="btn btn-primary px-15 mr-2"
                            id="component_save"
                    >{{ $t("SAVE") }}
                    </a>
                    <a v-on:click="cancel()" class="btn btn-secondary"
                    >{{ $t("CANCEL") }}
                    </a>
                </div>
                <div v-else class="card-toolbar">
                    <a v-on:click="edit()" class="btn btn-primary px-10"
                    >{{ $t("EDIT") }}
                    </a>
                </div>
            </div>
            <!--end::Header-->
            <!--begin::Form-->
            <form class="form">
                <!--begin::Body-->
                <div class="card-body">
                    <form class="form" id="component_form">
                        <fieldset id="edit_wrapper" :disabled="!edit_mode">
                            <div class="form-group row">
                                <label
                                        class="col-xl-3 col-lg-3 col-form-label text-right"
                                        :class="edit_mode ? 'required' : ''"
                                >{{ $t("COMPONENT.NAME") }}</label
                                >
                                <div class="col-lg-9 col-xl-6">
                                    <input
                                            ref="name"
                                            name="name"
                                            class="form-control form-control-md form-control-solid"
                                            type="text"
                                            v-model="component.name"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-xl-3"></label>
                                <div class="col-lg-9 col-xl-6">
                                    <h5 class="font-weight-bold mt-10 mb-6">
                                        {{ $t("COMPONENT.MATERIALS") }}
                                    </h5>
                                </div>
                            </div>
                            <template v-for="(material, i) in component.materials">
                                <div
                                        v-bind:key="i"
                                        class="form-group row"
                                        :class="i == component.materials.length - 1 && 'mb-0'"
                                >
                                    <label
                                            class="col-xl-3 col-lg-3 col-form-label text-right"
                                            :class="edit_mode && i === 0 ? 'required' : ''"
                                    >{{ $t("COMPONENT.MATERIAL") }}</label
                                    >
                                    <div class="col-lg-6 col-xl-4">
                                        <input
                                                :name="'material_name_' + i"
                                                :placeholder="$t('COMPONENT.NAME')"
                                                class="form-control form-control-md form-control-solid"
                                                type="text"
                                                v-model="material.name"
                                        />
                                    </div>

                                    <div class="col-lg-3 col-xl-2">
                                        <div class="input-group input-group-md input-group-solid">
                                            <input
                                                    :name="'material_amount_' + i"
                                                    :placeholder="$t('COMPONENT.AMOUNT')"
                                                    class="form-control form-control-md form-control-solid"
                                                    type="text"
                                                    v-model="material.amount"
                                            />
                                            <div class="input-group-append">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <a
                                            v-if="edit_mode && i === 0"
                                            v-on:click="addMaterial()"
                                            class="btn btn-icon btn-light btn-hover-primary btn-md"
                                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                              src="media/svg/icons/Navigation/Plus.svg"
                              :title="$t('ADD')"
                      ></inline-svg>
                        <!--end::Svg Icon-->
                    </span>
                                    </a>
                                    <a
                                            v-else-if="edit_mode"
                                            v-on:click="removeMaterial(i)"
                                            class="btn btn-icon btn-light btn-hover-primary btn-md"
                                    >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <inline-svg
                              src="media/svg/icons/Navigation/Minus.svg"
                              :title="$t('REMOVE')"
                      ></inline-svg>
                        <!--end::Svg Icon-->
                    </span>
                                    </a>
                                </div>
                            </template>
                        </fieldset>
                    </form>
                </div>
                <!--end::Body-->
            </form>
            <!--end::Form-->
        </div>
    </div>
</template>

<script>
    import {
        ADD_BREADCRUMB,
        SET_BREADCRUMB,
    } from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import KTUtil from "@/assets/js/components/util";

    export default {
        name: "componentmaterials",
        data() {
            return {
                add_new: false,
                edit_mode: false,
                not_found: false,
                original_component: null,
                component: {
                    name: null,
                    materials: [],
                },
            };
        },
        mounted() {
            if (this.$route.params.id == "new" && this.$route.params.color_id) {
                this.add_new = true;
                this.edit_mode = true;
                this.addMaterial();
                this.$store.dispatch(ADD_BREADCRUMB, [
                    {title: this.$t("COMPONENT.NEW")},
                ]);
            } else {
                ApiService.get("component/" + this.$route.params.id)
                    .then((data) => {
                        this.original_component = data.data;
                        this.component = JSON.parse(JSON.stringify(this.original_component));
                        if (data.headers.breadcrumbs) {
                            var breadcumbs = JSON.parse(
                                Buffer.from(data.headers.breadcrumbs, "base64").toString("utf-8")
                            );
                            this.$store.dispatch(SET_BREADCRUMB, [
                                {
                                    title: breadcumbs.product.name,
                                    route: "/product/" + breadcumbs.product.id,
                                },
                                {
                                    title: breadcumbs.color.name,
                                    route: "/color/" + breadcumbs.color.id,
                                },
                                {title: data.data.name},
                            ]);
                        }
                        if (this.$route.params.edit) {
                            this.edit_mode = true;
                        }
                    })
                    .catch(() => {
                        this.not_found = true;
                        this.$store.dispatch(SET_BREADCRUMB, []);
                    });
            }

            this.material_name_validators = {
                validators: {
                    notEmpty: {
                        message: this.$t("VALIDATION.REQUIRED", {
                            name: this.$t("COMPONENT.NAME"),
                        }),
                    },
                },
            };

            this.material_amount_validators = {
                validators: {
                    notEmpty: {
                        message: this.$t("VALIDATION.REQUIRED", {
                            name: this.$t("COMPONENT.AMOUNT"),
                        }),
                    },
                    regexp: {
                        regexp: /^[1-9][0-9]?$|^100$/,
                        message: this.$t("VALIDATION.PERCENTAGE", {
                            name: this.$t("COMPONENT.AMOUNT"),
                        }),
                    },
                },
            };

            this.fv = formValidation(KTUtil.getById("component_form"), {
                fields: {
                    name: {
                        validators: {
                            notEmpty: {
                                message: this.$t("VALIDATION.REQUIRED", {
                                    name: this.$t("COMPONENT.NAME"),
                                }),
                            },
                        },
                    },
                },
                plugins: {
                    trigger: new Trigger(),
                    bootstrap: new Bootstrap(),
                },
            });
        },
        methods: {
            edit() {
                this.edit_mode = true;
            },
            cancel() {
                if (this.add_new) {
                    this.$router.push({
                        name: "color",
                        params: {
                            id: this.$route.params.color_id,
                            tab: "COMPONENTS_AND_LAUNDRY",
                        },
                    });
                } else {
                    this.component = JSON.parse(JSON.stringify(this.original_component));
                    this.fv.resetForm(true);
                    this.edit_mode = false;
                }
            },
            save() {
                this.fv.validate().then((status) => {
                    if (status == "Valid") {
                        KTUtil.getById("component_save").classList.add(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );

                        if (this.add_new) {
                            ApiService.post(
                                "color/" + this.$route.params.color_id + "/component",
                                this.component
                            )
                                .then(() => {
                                    KTUtil.getById("component_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                    this.cancel();
                                })
                                .catch(() => {
                                    KTUtil.getById("component_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                });
                        } else {
                            ApiService.put("component/" + this.component.id, this.component)
                                .then((data) => {
                                    KTUtil.getById("component_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                    this.original_component = data.data;
                                    this.cancel();
                                })
                                .catch(() => {
                                    KTUtil.getById("component_save").classList.remove(
                                        "spinner",
                                        "spinner-light",
                                        "spinner-right"
                                    );
                                });
                        }
                    }
                });
            },
            addMaterial() {
                this.component.materials.push({name: null, amount: null});
            },
            removeMaterial(index) {
                this.component.materials.splice(index, 1);
            },
        },
        watch: {
            "component.materials"() {
                this.$nextTick(() => {
                    var old_fields = this.fv.getFields();
                    for (var key in old_fields) {
                        if (key.startsWith("material_")) {
                            this.fv.removeField(key);
                        }
                    }
                    this.component.materials.forEach((material, index) => {
                        this.fv
                            .addField("material_name_" + index, this.material_name_validators)
                            .addField(
                                "material_amount_" + index,
                                this.material_amount_validators
                            );
                    });
                });
            },
        },
    };
</script>
